import React, { useState } from 'react';
import { IBillableSession, IBillableSessionManual, IFetchedBillableSession } from '../../../types/models';
import * as S from './index.styles';
import { getSessionLengthStringMinsAndSecs } from '../Utils/utils';
import { Modal } from '@apps/common-ui';
import toast from 'react-hot-toast';
import ApiError from '@apps/common-utilities/src/api/ApiError';
import { RequestMethod, useApiRequest, ApiResponse } from '../../../hooks/useApiRequest';
import SessionDateForm from '../SessionDateForm';
import { differenceInSeconds } from 'date-fns';
import { formatDateForPatientPage, formatDateTimeForInput } from '../../../utils/dateUtils';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    billableSession?: IFetchedBillableSession;
    refreshSessions: () => void;
}
export const EditEntryModal = ({ showModal, dismissModal, billableSession, refreshSessions }: Props) => {
    const [newDate, setNewDate] = useState<Date>(billableSession ? new Date(billableSession.startDate) : new Date());
    const [currentEndDate, setCurrentEndDate] = useState<Date>(billableSession ? new Date(billableSession.endDate) : new Date());

    const [newDuration, setNewDuration] = useState<number>(differenceInSeconds(currentEndDate, newDate));
    const [originalDuration, setOriginalDuration] = useState<number>(differenceInSeconds(currentEndDate, newDate));

    const { callApi } = useApiRequest<IBillableSession>(RequestMethod.PUT);
    const [apiError, setApiError] = useState('');

    const changeDate = (date: Date) => {
        const newDateEntered = new Date(newDate);
        newDateEntered.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewDate(newDateEntered);
    };

    const checkChanges = (date: string) => {
        const originalStartDate = new Date(date);
        if (formatDateTimeForInput(originalStartDate) === formatDateTimeForInput(newDate) && originalDuration === newDuration) {
            return true;
        }
        return false;
    };

    const updateSession = async () => {
        if (!billableSession) {
            return;
        }

        const body: IBillableSessionManual = {
            userId: billableSession.userId,
            providerId: billableSession.providerId,
            providerName: billableSession.providerName,
            date: newDate.toISOString().split('T')[0],
            duration: newDuration,
            status: billableSession.status
        };

        await callApi(`/users/${billableSession.userId}/billable-sessions/${billableSession.id}`, body)
            .then(({ response, error }: ApiResponse<IBillableSession>) => {
                if (!!error.error || !!error.message) {
                    setApiError(error.error || error.message);
                    toast.error('Error updating timer entry');
                } else if (response.data.id) {
                    toast.success('Timer entry updated');
                    refreshSessions();
                    dismissModal();
                }
            }).catch((err: ApiError) => {
                toast.error('Error updating timer entry');
            });
    };

    if (!billableSession) {
        return null;
    }

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="800px">
            <S.Container>
                <S.Title>Edit Time Entry</S.Title>
                <S.TimeContainer>
                    <S.Time>
                        Original time: <b>{getSessionLengthStringMinsAndSecs(originalDuration)}</b> on {formatDateForPatientPage(new Date(billableSession.startDate))}
                    </S.Time>
                </S.TimeContainer>
                <SessionDateForm
                  newDate={newDate}
                  setNewDate={setNewDate}
                  newDuration={newDuration}
                  setNewDuration={setNewDuration}
                  changeDate={changeDate}
                />
                <S.NewTime>New time: <b>{getSessionLengthStringMinsAndSecs(newDuration)}</b> on {formatDateForPatientPage(newDate)}</S.NewTime>
                {!!apiError && <S.ErrorMessage>{apiError}</S.ErrorMessage>}
                <S.ButtonContainer>
                    <S.ConfirmButton onClick={() => updateSession()} disabled={!newDuration || newDuration === 0 || checkChanges(billableSession.startDate)}>Confirm Changes</S.ConfirmButton>
                    <S.CancelButton onClick={dismissModal}>Cancel</S.CancelButton>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};

export default EditEntryModal;
