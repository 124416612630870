import React from 'react';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { format } from 'date-fns';
import { NaiveDate } from '../../../utils/dateUtils';

interface Props {
    newDate: Date;
    setNewDate: (date: Date) => void;
    newDuration: number;
    setNewDuration: (duration: number) => void;
    changeDate: (date: Date) => void;
}

export const SessionDateForm = ({ newDate, setNewDate, changeDate, newDuration, setNewDuration }: Props) => {
    const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const minutes = parseInt(e.target.value, 10) || 0; // Ensure the value is a number
        const seconds = newDuration % 60; // Preserve current seconds
        setNewDuration(minutes * 60 + seconds); // Update duration as a numeric value
    };

    const handleSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const seconds = parseInt(e.target.value, 10) || 0; // Ensure the value is a number
        const minutes = Math.floor(newDuration / 60); // Preserve current minutes
        setNewDuration(minutes * 60 + seconds); // Update duration as a numeric value
    };
    return (
        <S.StartEndTimesRow>
            <S.InputsGroupContainer>
                <S.InputsGroupTitle>Duration</S.InputsGroupTitle>
                <S.InputsGroup>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="start-time-minutes"
                          type="number"
                          value={Math.floor(newDuration / 60)}
                          min="0"
                          max="59"
                          onChange={handleMinutesChange}
                        />
                        <S.InputLabel htmlFor="start-time-minutes">min</S.InputLabel>
                    </S.SingleInputContainer>
                    <S.SingleInputContainer>
                        <S.TimeInput
                          id="start-time-seconds"
                          type="number"
                          value={newDuration % 60}
                          min="0"
                          max="59"
                          onChange={handleSecondsChange}
                        />
                        <S.InputLabel htmlFor="start-time-seconds">sec</S.InputLabel>
                    </S.SingleInputContainer>
                </S.InputsGroup>
            </S.InputsGroupContainer>
            <S.ArrowContainer>
                <FontAwesomeIcon icon={faArrowRight as IconProp} />
            </S.ArrowContainer>
            <S.DateContainer>
                <S.DateTitle htmlFor="date">Date</S.DateTitle>
                <S.DateSelect
                  id="date"
                  value={format(newDate, 'yyyy-MM-dd')}
                  type="date"
                  max={format(new Date(), 'yyyy-MM-dd')}
                  onChange={(e) => {
                            changeDate(new NaiveDate(e.target.value));
                          }}
                />
            </S.DateContainer>
        </S.StartEndTimesRow>
    );
};

export default SessionDateForm;
