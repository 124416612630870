import { types } from '@apps/common-utilities';
import { VisitFrequencyOptions } from '@apps/common-utilities/src/types/maintenanceRecommendation';
import { NumberLocale } from 'yup/lib/locale';

export enum MedicalCondition {
    ASTHMA = 'ASTHMA',
    COPD = 'COPD',
    ILD = 'ILD',
    BRONCHIECTASIS = 'BRONCHIECTASIS',
    PULMONARY_HYPERTENSION = 'PULMONARY_HYPERTENSION',
    CYSTIC_FIBROSIS = 'CYSTIC_FIBROSIS',
    POST_COVID = 'POST_COVID',
}

export enum ActionPlanZoneType {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED'
}

export enum ActionplanInputType {
    MEDICATION = 'MEDICATION',
    ACTION = 'ACTION'
}

export enum PhlegmColor {
    CLEAR = 'CLEAR',
    WHITE = 'WHITE',
    YELLOW_WHITE = 'YELLOW_WHITE',
    YELLOW_GREEN = 'YELLOW_GREEN',
    GREEN = 'GREEN'
}

export enum PatientStatus {
    VPR_ACTIVE = 'VPR_ACTIVE',
    VPR_PAUSED = 'VPR_PAUSED',
    VPR_DISCHARGED = 'VPR_DISCHARGED',
    MNT_ACTIVE = 'MNT_ACTIVE',
    MNT_PAUSED = 'MNT_PAUSED',
    MNT_DISCHARGED = 'MNT_DISCHARGED',
}

export interface IUser {
    id: string;
    healthieId: string;
    email: string;
    firstName: string;
    lastName: string;
    weight: string;
    height: string;
    dateOfBirth: string;
    sexAssignedAtBirth: string;
    phoneNumber: string;
    primaryDiagnosis: string;
    groupCode: string;
    primaryRespiratoryTherapist: ICoach | null;
    primaryPhysicalTherapist: ICoach | null;
    primaryNursePractitioner: ICoach | null;
    coaches: ICoach[];
    roles: IRole[];
}

export interface IMaintenanceRecommendation {
    id?: number,
    recommended: boolean | null | undefined,
    visitFrequency: VisitFrequencyOptions,
    visitLengthMinutes: number | null,
    durationMonths: number | null,
    maintenanceRecommendationType: MaintenanceRecommendationType
}

export interface IPatient {
    id: string;
    healthieId: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    healthieFirstName: string;
    healthieLastName: string;
    fullLegalName: string;
    weight: string;
    height: string;
    dateOfBirth: string;
    sexAssignedAtBirth: string;
    actionPlanType: MedicalCondition | null;
    groupCode: string;
    flatRateBilling: boolean;
    primaryRespiratoryTherapist: ICoach | null;
    primaryPhysicalTherapist: ICoach | null;
    primaryNursePractitioner: ICoach | null;
    coaches: ICoach[];
    vPRStatus: vPRStatusEnum;
    PCMStatus: PCMStatusEnum;
    isPaused: boolean;
    ptProgramStatus: PatientStatus | null;
    rrtProgramStatus: PatientStatus | null;
    errors: IError[];
    nextNPAppointmentDate: string;
    nextRTAppointmentDate: string;
    nextPTAppointmentDate: string;
    lastAttendedPTAppointmentDate: string;
    lastAttendedRTAppointmentDate: string;
    lastAttendedNPAppointmentDate: string;
    missedPTAppointmentCount: number;
    missedRTAppointmentCount: number;
    missedNPAppointmentCount: number;
    ptMaintenanceRecommendation: IMaintenanceRecommendation | null;
    pcmMaintenanceRecommendation: IMaintenanceRecommendation | null;
    midpointApptScheduled: boolean;
    rrtPlanOfCareStartDate: string;
    rrtPlanOfCareLastUpdated: string;
}

export interface IRole {
    id: string;
    billable: boolean;
    role: UserRoles;
}

export interface ICoach extends Omit<IUser, 'id'>{
    userId: string;
    name: string
}

export interface IActionPlanItem {
    id?: number;
    inputType: ActionplanInputType;
    value: string;
}

export interface IActionPlanZone {
    id?: number;
    zone: ActionPlanZoneType;
    zoneItems: IActionPlanItem[];
    minFlowRate: number;
    maxFlowRate: number;
}

export interface IProviderInfo {
    providerName: string;
    providerPhone: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
}

export interface IGenericActionPlan {
    id?: number;
    version?: number;
    lang: string;
    userId: string | null;
    coachId: string | null;
    createdAt: string;
    createdBy: string | null;
    providerName: string;
    phoneNumber: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
    published: boolean;
    actionPlanZones: IActionPlanZone[]
    copdInfo?: ICopdInfo;
    isActive?: boolean;
}

export interface IShortnessOfBreathCause {
    id?: number;
    cause: string;
}

export interface ICopdInfo {
    phlegmColour: PhlegmColor;
    shortnessOfBreathCauses: IShortnessOfBreathCause[];
}

export interface IAsthmaActionPlan extends IGenericActionPlan {

}

export interface UserAssignment {
    coachAssignment?: UserAssignmentCoachAssignment | null
    groupCode?: string | null
    primaryCondition?: MedicalCondition | null
}

export interface UserAssignmentCoachAssignment {
    primaryCoachId: string,
    assignedCoachIds: string[]
}

export interface ExerciseFunctions {
    cloneExercise: Function;
    cloneDay: Function;
    removeExercise: Function;
    removeDay: Function;
    moveExerciseUp: Function;
    moveExerciseDown: Function;
}

export enum BillableSessionStatus {
    EDITED = 'EDITED',
    MANUALLY_CREATED = 'MANUALLY_CREATED',
    HEALTHIE_CHARTING_NOTE = 'HEALTHIE_CHARTING_NOTE',
}

export enum BillableSessionType {
    SYNC = 'SYNC',
    ASYNC = 'ASYNC'
}

export interface IFetchedGenericSession {
    sessionId: number;
    calendarDate: string;
    type: BillableSessionType;
    durationSeconds: number;
    providerName: string;
    providerId: string;
    providerRole: UserRoles;
    status?: BillableSessionStatus;
}

export interface IBillableSessionMonthSummary {
    calendarMonth: string;
    totalSeconds: number;
    totalAsyncSeconds: number;
    totalSyncSeconds: number;
    totalBillingUnits: number;
    maxBillingUnits: number;
    maxSeconds: number;
    sessions: IFetchedGenericSession[];
}

export interface IBillingMonthlySummary {
    calendarMonth: string;
    minutes: number;
    billingUnits98980: number;
    billingUnits98981: number;
}

export interface IBillingTotalSummary {
    monthlySummaries: IBillingMonthlySummary[];
    totalMinutes: number;
    totalBillingUnits98980: number;
    totalBillingUnits98981: number;
}

export interface ISyncBillingTotalSummary {
    calendarMonth: string;
    totalMinutes: number;
}

export interface IBillableSession {
    id?: number;
    startDate: Date;
    endDate: Date;
    userId: string;
    providerId: string;
    providerName: string;
    status?: BillableSessionStatus;
}

export interface IBillableSessionManual {
    id?: number;
    date: string;
    userId: string;
    providerId: string;
    providerName: string;
    status?: BillableSessionStatus;
    duration: number;
}

export interface IBillableSessionCreationResponse {
    id?: number;
    startDate: Date;
    endDate: Date;
    userId: string;
    providerId: string;
    providerName: string;
    timerPopupInterval: number;
    stopTimerInterval: number;
    status?: BillableSessionStatus;
}

export interface IFetchedBillableSession {
    id: number;
    calendarDate: string;
    seconds: number;
    startDate: string;
    endDate: string;
    userId: string;
    providerId: string;
    providerName: string;
    status?: BillableSessionStatus;
    providerRole: UserRoles;
}

export interface ICurrent98976PeriodSummary {
    daysWith98976Data: number;
    daysLeftInPeriod: number;
    isDischarged: boolean;
    evalPending: boolean;
    currentPeriodStartDate: Date;
    currentPeriodEndDate?: Date;
    previousPeriodEndDate?: Date;
    nextPeriodStartDate?: Date;
}

export enum UserRoles {
    PHYSICAL_THERAPIST = 'PHYSICAL_THERAPIST',
    RESPIRATORY_THERAPIST = 'RESPIRATORY_THERAPIST',
    NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
    COACH = 'COACH'
}

export interface IVideoGenerationJob {
    id: number;
    status: VideoGenerationJobStatus;
    draftVideoUrl: string;
}

export enum VideoGenerationJobStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export enum ProgramStatus {
    ACTIVE = 'ACTIVE',
    NOT_ENROLLED = 'NOT_ENROLLED',
    DISCHARGED = 'DISCHARGED',
}

export enum vPRStatusEnum {
    VPR = 'VPR',
    MAINTENANCE = 'MAINTENANCE',
}

export enum PCMStatusEnum {
    PCM = 'PCM',
}

export enum CareType {
    PT = 'PT',
    RRT = 'RRT',
}

export interface IPatientListUser {
    id: string;
    email: string;
    fullName: string;
    healthieId: string;
    diagnosis: string;
    lastViewedAt: string;
    taskCount: number;
    // PT Fields
    unseenExerciseWarningsCount: number;
    unseenExerciseCommentCount: number;
    planOfCareStartDate: string;
    planOfCareEndDate: string;
    exerciseActivity7Days: number;
    breatherActivity7Days: number;
    ptProgramStatus: PatientStatus | null;
    nextPtAppointment: string;
    lastPtAppointment: string;
    ptTotalBillingSeconds: number;
    ptRemainingBillingSecondsUntilNextUnit: number;
    ptBillingSecondsPerUnit: number;
    ptMaxBillingSeconds: number;
    ptMaxBillingUnits: number;
    flatRatePTBilling: boolean;
    // RT/NP Fields
    educationCompletedPercentage: number;
    educationActivity30Days: number;
    nextRrtAppointment: string;
    lastRrtAppointment: string;
    rrtPlanLastUpdated: string;
    rrtProgramStatus: PatientStatus | null;
    rrtTotalBillingSeconds: number;
    rrtRemainingBillingSecondsUntilNextUnit: number;
    rrtBillingSecondsPerUnit: number;
    rrtMaxBillingSeconds: number;
    rrtMaxBillingUnits: number;
}

export enum IErrorType {
    APPOINTMENT_MISSING_STATUS = 'APPOINTMENT_MISSING_STATUS',
    APPOINTMENT_NOT_BOOKED = 'APPOINTMENT_NOT_BOOKED',
    CHARTING_NOTE_NOT_LOCKED = 'CHARTING_NOTE_NOT_LOCKED',
    NO_CCP_CREATED = 'NO_CCP_CREATED',
    WRONG_APPOINTMENT_LENGTH = 'WRONG_APPOINTMENT_LENGTH',
    EXERCISE_PLAN_ENDED = 'EXERCISE_PLAN_ENDED',
    PT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED = 'PT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED',
    RRT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED = 'RRT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED',
}

export interface IError {
    type: IErrorType;
    message: string;
}

export interface IPatientError {
    id: string;
    healthieId: string;
    ptProgramStatus: PatientStatus | null;
    rrtProgramStatus: PatientStatus | null;
    healthieFirstName: string;
    healthieLastName: string;
    email: string;
    errors: IError[];
    isPrimaryCoach: boolean;
    isCoach: boolean;
}

export enum MaintenanceRecommendationType {
    PT = 'PT',
    PCM = 'PCM',
}

export enum MaintenanceRecommendationOptions {
    Yes = 'Yes',
    No = 'No',
    Undetermined = 'Undetermined'
}
