import React, { useState } from 'react';
import * as S from './index.styles';
import { Modal } from '@apps/common-ui';
import { IBillableSessionCreationResponse, IBillableSessionManual, IUser } from '../../../types/models';
import toast from 'react-hot-toast';
import { getSessionLengthStringMinsAndSecs } from '../Utils/utils';
import { ApiResponse, RequestMethod, useApiRequest } from '../../../hooks/useApiRequest';
import SessionDateForm from '../SessionDateForm';
import { formatDateForPatientPage } from '../../../utils/dateUtils';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
    patientId: string;
    provider: IUser | null;
    refreshSessions: () => void;
}

const AddEntryModal = ({ showModal, dismissModal, provider, patientId, refreshSessions }: Props) => {
    const [newDate, setNewDate] = useState(new Date());
    const [newDuration, setNewDuration] = useState<number>(0);

    const [apiError, setApiError] = useState<string>('');
    const { callApi } = useApiRequest<IBillableSessionCreationResponse>(RequestMethod.POST);

    const changeDate = (date: Date) => {
        const newDateEntered = new Date(newDate);
        newDateEntered.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        setNewDate(newDateEntered);
    };

    const createSession = async () => {
        if (!provider?.id || !patientId) {
            return;
        }

        const body: IBillableSessionManual = {
            userId: patientId,
            providerId: provider.id,
            providerName: `${provider.firstName} ${provider.lastName}`,
            date: newDate.toISOString().split('T')[0],
            duration: newDuration,
        };

        // send create timer event to backend
        await callApi(`/users/${patientId}/billable-sessions`, body)
            .then(({ response, error }: ApiResponse<IBillableSessionCreationResponse>) => {
                if (!!error.error || !!error.message) {
                    setApiError(error.error || error.message);
                } else if (response.data.id) {
                    toast.success('Timer entry created');
                    refreshSessions();
                    dismissModal();
                }
            }).catch((err) => {
                toast.error('Error creating timer entry');
            });
    };

    return (
        <Modal showModal={showModal} dismissModal={dismissModal} showTopBar={false} closeOnOutsideClick={false} minWidth="800px">
            <S.Container>
                <S.Title>Add Time Entry</S.Title>
                <S.Subtitle>
                    Please enter the duration of the billable session you had. Make sure to update these fields to the best of your knowledge to capture the time
                </S.Subtitle>
                <SessionDateForm
                  newDate={newDate}
                  setNewDate={setNewDate}
                  newDuration={newDuration}
                  setNewDuration={setNewDuration}
                  changeDate={changeDate}
                />
                <S.NewTime>Add time: <b>{getSessionLengthStringMinsAndSecs(newDuration)}</b> on {formatDateForPatientPage(newDate)}</S.NewTime>
                {!!apiError && <S.ErrorMessage>{apiError}</S.ErrorMessage>}
                <S.ButtonContainer>
                    <S.ConfirmButton
                      onClick={() => createSession()}
                      disabled={!newDuration || newDuration === 0}
                    >
                        Add Entry
                    </S.ConfirmButton>
                    <S.CancelButton onClick={dismissModal}>Cancel</S.CancelButton>
                </S.ButtonContainer>
            </S.Container>
        </Modal>
    );
};
export default AddEntryModal;
