import { useEffect, useRef } from 'react';
var useScrollIntoViewOnExpand = function (_a) {
    var expanded = _a.expanded, _b = _a.behavior, behavior = _b === void 0 ? 'auto' : _b;
    var titleRef = useRef(null);
    var bodyRef = useRef(null);
    useEffect(function () {
        var _a;
        if (expanded && titleRef.current) {
            var headerRect = titleRef.current.getBoundingClientRect();
            var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            var contentHeight = ((_a = bodyRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0;
            var componentHeight = headerRect.height + contentHeight;
            var isInView = (headerRect.top >= 0) && (headerRect.bottom <= viewportHeight - componentHeight);
            if (!isInView) {
                var offset = componentHeight;
                var targetScrollPosition = headerRect.top + window.scrollY - viewportHeight + offset;
                window.scrollTo({
                    top: targetScrollPosition,
                    behavior: behavior,
                });
            }
        }
    }, [expanded, behavior]);
    return { titleRef: titleRef, bodyRef: bodyRef };
};
export default useScrollIntoViewOnExpand;
