import React, { useEffect, useRef, useState } from 'react';
import * as SParent from '../../index.styles';
import * as S from './index.styles';
import CollapseExpandButton from '../../../../../../components/CollapseExpandButton';
import CourseProgress from './components/CourseProgressCard';
import ActionPlan from './components/ActionPlan';
import CarePlan from './components/CarePlan';
import { useScrollIntoViewOnExpand } from '@apps/common-utilities';

type Props = {
    expandedState: boolean;
}

const CareManagementAndCourseRow = ({ expandedState } : Props) => {
    const [expanded, setExpanded] = useState(expandedState);
    const { titleRef, bodyRef } = useScrollIntoViewOnExpand<HTMLDivElement, HTMLDivElement>({ expanded });

    return (
        <>
            <SParent.HeaderRow ref={titleRef}>
                <SParent.Header>Care Management</SParent.Header>
                <CollapseExpandButton
                  expanded={expanded}
                  onClick={() => {
                    setExpanded(prev => !prev);
                }}
                />
            </SParent.HeaderRow>
            {expanded && (
                <S.InfoRow ref={bodyRef}>
                    <CarePlan />
                    <CourseProgress />
                    <ActionPlan />
                </S.InfoRow>
            )}
        </>
    );
};

export default CareManagementAndCourseRow;
