import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faCircleCheck, faCommentDots, faCalendarCheck, faFileLines } from '@fortawesome/free-regular-svg-icons';
import { faExclamationCircle, faArrowUpLong, faArrowDownLong, faBook, faWalking, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPatientListUser, IPatientError, UserRoles, IErrorType, IError, PatientStatus } from '../../../types/models';
import PatientDataTableCells from './PatientDataTableCells';
import * as S from './index.styles';
import ErrorDataTableCells from './ErrorDataTableCells';
import { formatDateForPatientList, getColorForPlanOfCare, isBoldForPlanOfCare, showPlanOfCareWarning } from '../../../utils/dateUtils';
import PatientError from '../../../components/PatientErrorList';
import CollapseExpandButton from '../../../components/CollapseExpandButton';
import { TableColumn } from 'react-data-table-component';
import { format } from 'date-fns';
import { getColorForBilling, getIsBoldForBilling, hasReachedPtMaxBillingUnits, getPtMaxBillingUnitsForUser, hasReachedRrtMaxBillingUnits, getPtMaxBillingSeconds } from '../../../utils/billingUtils';
import { getColorForCourses } from '../../../utils/courseUtils';
import BreatherImage from '../../../assets/breatherImage.png';

export enum PlanOfCareDateColor {
    YELLOW = 'yellow',
    RED = 'red',
    BLACK = 'black'
}

export const ErrorPriority: Record<IErrorType, number> = {
    [IErrorType.WRONG_APPOINTMENT_LENGTH]: 1,
    [IErrorType.CHARTING_NOTE_NOT_LOCKED]: 2,
    [IErrorType.APPOINTMENT_NOT_BOOKED]: 3,
    [IErrorType.NO_CCP_CREATED]: 4,
    [IErrorType.APPOINTMENT_MISSING_STATUS]: 5,
    [IErrorType.EXERCISE_PLAN_ENDED]: 6,
    [IErrorType.PT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED]: 7,
    [IErrorType.RRT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED]: 7,
};

export const CriticalErrorTypes = [
    IErrorType.WRONG_APPOINTMENT_LENGTH,
    IErrorType.CHARTING_NOTE_NOT_LOCKED,
    IErrorType.APPOINTMENT_NOT_BOOKED,
];

export const ErrorMessages: Record<IErrorType, string> = {
    [IErrorType.WRONG_APPOINTMENT_LENGTH]: 'Appointment booked for 60 min, max allowed, 45 min',
    [IErrorType.CHARTING_NOTE_NOT_LOCKED]: 'Charting Note Not Locked',
    [IErrorType.APPOINTMENT_NOT_BOOKED]: 'Next appointment not booked',
    [IErrorType.NO_CCP_CREATED]: 'No Comprehensive Care Plan Created',
    [IErrorType.APPOINTMENT_MISSING_STATUS]: 'No appointment status marked on previous appointment',
    [IErrorType.EXERCISE_PLAN_ENDED]: 'End Date of Exercise Plan has Passed',
    [IErrorType.PT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED]: 'PT Monthly Statement Not Signed',
    [IErrorType.RRT_SPECIFIC_CHARTING_NOTE_NOT_LOCKED]: 'RT Monthly Statement Not Signed'
};

export type CollapsedTableData = [{
    totalErrors: number,
    totalCriticalErrors: number,
    totalStandardErrors: number,
}];

const TableColumnHeader = (header: string) => {
    return (
        <S.TableColumnHeaderContainer>
            <S.TableColumnHeader>{header}</S.TableColumnHeader>
        </S.TableColumnHeaderContainer>
    );
};

const TableColumnHeaderWithSubheader = (header: string, subheader: string) => {
    return (
        <S.TableColumnHeaderContainer>
            <S.TableColumnHeader>{header}</S.TableColumnHeader>
            <S.TableColumnSubheader>{subheader}</S.TableColumnSubheader>
        </S.TableColumnHeaderContainer>
    );
};

export const getPatientColumnsForRole = (role: UserRoles | null): TableColumn<IPatientListUser>[] => {
    let columns = [
        {
            name: TableColumnHeader('Status'),
            grow: 0.8,
            sortable: false,
            cell: PatientDataTableCells.StatusCell,

        },
        {
            name: TableColumnHeader('Patient'),
            grow: 1.5,
            sortable: true,
            sortField: 'name',
            cell: PatientDataTableCells.PatientCell,
        },

    ];
    if (role === UserRoles.PHYSICAL_THERAPIST) {
        columns = [
            ...columns,
            {
                name: TableColumnHeader('Plan of Care'),
                grow: 1.2,
                sortable: true,
                sortField: 'planOfCareEndDate',
                cell: (row: IPatientListUser) => {
                    const isDischarged = row.ptProgramStatus === PatientStatus.VPR_DISCHARGED || row.ptProgramStatus === PatientStatus.MNT_DISCHARGED;
                    return (
                        <S.TableColumn data-tag="allowRowEvents">
                            {row.planOfCareStartDate ? (
                                <>
                                    <S.TableText data-tag="allowRowEvents">Start: {formatDateForPatientList(new Date(row.planOfCareStartDate))}</S.TableText>
                                    <S.TableText
                                      data-tag="allowRowEvents"
                                      color={getColorForPlanOfCare(true, row.planOfCareEndDate, isDischarged)}
                                      isBold={isBoldForPlanOfCare(true, row.planOfCareEndDate, isDischarged)}
                                    >
                                        End: {formatDateForPatientList(new Date(row.planOfCareEndDate))}
                                        {showPlanOfCareWarning(true, row.planOfCareEndDate, isDischarged)
                                        && <FontAwesomeIcon icon={faTriangleExclamation as IconProp} />}
                                    </S.TableText>
                                </>
                            ) : (
                                <S.TableText data-tag="allowRowEvents">No plan set</S.TableText>
                            )}
                        </S.TableColumn>
                    );
                },
            }
        ];
    }
    if (role === UserRoles.RESPIRATORY_THERAPIST || role === UserRoles.NURSE_PRACTITIONER) {
        columns = [
            ...columns,
            {
                name: TableColumnHeaderWithSubheader('PCM Care Plan', '(Last Updated)'),
                grow: 1.2,
                sortable: true,
                sortField: 'planOfCareEndDate',
                cell: (row: IPatientListUser) => {
                    const isDischarged = row.rrtProgramStatus === PatientStatus.VPR_DISCHARGED || row.rrtProgramStatus === PatientStatus.MNT_DISCHARGED;
                    return (
                        <S.TableColumn data-tag="allowRowEvents">
                            {row.rrtPlanLastUpdated ? (
                                <S.TableText
                                  data-tag="allowRowEvents"
                                  color={getColorForPlanOfCare(false, row.rrtPlanLastUpdated, isDischarged)}
                                  isBold={isBoldForPlanOfCare(false, row.rrtPlanLastUpdated, isDischarged)}
                                >
                                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faFileLines as IconProp} />
                                    {format(new Date(row.rrtPlanLastUpdated), 'MM/dd/yyyy')}
                                    {showPlanOfCareWarning(false, row.rrtPlanLastUpdated, isDischarged)
                                    && <FontAwesomeIcon icon={faTriangleExclamation as IconProp} />}
                                </S.TableText>
                            )
                        : (
                            <S.TableText data-tag="allowRowEvents">No PCM Care Plan</S.TableText>
                        )}
                        </S.TableColumn>
                    );
                },
            }
        ];
    }
    if (role === UserRoles.PHYSICAL_THERAPIST || role === UserRoles.RESPIRATORY_THERAPIST || role === UserRoles.NURSE_PRACTITIONER) {
        columns = [
            ...columns,
            {
                name: TableColumnHeader('Appointments'),
                grow: 1,
                sortable: true,
                sortField: 'nextAppointment',
                cell: (row: IPatientListUser) => {
                    const appointments = {
                        nextAppointment: role === UserRoles.PHYSICAL_THERAPIST ? row.nextPtAppointment : row.nextRrtAppointment,
                        lastAppointment: role === UserRoles.PHYSICAL_THERAPIST ? row.lastPtAppointment : row.lastRrtAppointment,
                    };
                    return PatientDataTableCells.AppointmentCell(appointments);
                },
            },
            {
                name: TableColumnHeader('Alerts'),
                grow: 0.5,
                sortable: false,
                sortField: 'taskCount',
                cell: (row: IPatientListUser) => (
                    <S.TableColumn data-tag="allowRowEvents">
                        <S.TableText data-tag="allowRowEvents">
                            <FontAwesomeIcon data-tag="allowRowEvents" icon={faCircleCheck as IconProp} size="sm" />{row.taskCount}
                        </S.TableText>
                    </S.TableColumn>
                ),
            },
            {
                name: role === UserRoles.PHYSICAL_THERAPIST ? TableColumnHeaderWithSubheader('Activity', '(Last 7 Days)') : TableColumnHeaderWithSubheader('Activity', '(Last 30 Days)'),
                grow: 0.8,
                sortable: false,
                sortField: 'breatherActivity7Days',
                cell: (row: IPatientListUser) => {
                    return role === UserRoles.PHYSICAL_THERAPIST
                        ? (
                            <S.TableColumn data-tag="allowRowEvents">
                                <S.TableText data-tag="allowRowEvents">
                                    <S.Img data-tag="allowRowEvents" src={BreatherImage} alt="RMT" /> {row.breatherActivity7Days}
                                </S.TableText>
                                <S.TableText data-tag="allowRowEvents">
                                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faWalking as IconProp} size="sm" />{row.exerciseActivity7Days}
                                </S.TableText>
                            </S.TableColumn>
                        )
                        : (
                            <S.TableColumn data-tag="allowRowEvents">
                                <S.TableText data-tag="allowRowEvents">
                                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faBook as IconProp} size="sm" />{row.educationCompletedPercentage}%
                                </S.TableText>
                                <S.TableText
                                  data-tag="allowRowEvents"
                                  color={getColorForCourses(row.educationCompletedPercentage, row.educationActivity30Days)}
                                >
                                    <FontAwesomeIcon data-tag="allowRowEvents" icon={faCalendar as IconProp} size="sm" />{row.educationActivity30Days}
                                </S.TableText>
                            </S.TableColumn>
                        );
                },
            },
            {
                name: role === UserRoles.PHYSICAL_THERAPIST ? TableColumnHeader('RTM') : TableColumnHeader('PCM'),
                grow: 0.5,
                sortable: true,
                sortField: 'billing',
                cell: (row: IPatientListUser) => (
                    <S.TableColumn data-tag="allowRowEvents">
                        {role === UserRoles.PHYSICAL_THERAPIST ? (
                            <>
                                <S.TableText data-tag="allowRowEvents">
                                    {/* TODO: Pull flat rate billing values from the backend */}
                                    {hasReachedPtMaxBillingUnits(row)
                                        ? getPtMaxBillingUnitsForUser(row)
                                        : Math.floor(row.ptTotalBillingSeconds / row.ptBillingSecondsPerUnit)}/{row.flatRatePTBilling ? 2 : row.ptMaxBillingUnits} Units
                                </S.TableText>
                                <S.TableTextBillingSeconds
                                  data-tag="allowRowEvents"
                                  color={getColorForBilling(row.ptTotalBillingSeconds, getPtMaxBillingSeconds(row), getPtMaxBillingUnitsForUser(row), true)}
                                  isBold={getIsBoldForBilling(row.ptTotalBillingSeconds, getPtMaxBillingSeconds(row), getPtMaxBillingUnitsForUser(row), true)}
                                >
                                    {hasReachedPtMaxBillingUnits(row) ? 'Max units reached' : `${Math.floor((row.ptTotalBillingSeconds % (row.ptMaxBillingSeconds / row.ptMaxBillingUnits)) / 60)}/${Math.round(row.ptMaxBillingSeconds / row.ptMaxBillingUnits / 60)} min`}
                                </S.TableTextBillingSeconds>
                            </>
                        ) : (
                            <>
                                <S.TableText data-tag="allowRowEvents">
                                    {hasReachedRrtMaxBillingUnits(row) ? row.rrtMaxBillingUnits : Math.floor(row.rrtTotalBillingSeconds / row.rrtBillingSecondsPerUnit)}/{row.rrtMaxBillingUnits} Units
                                </S.TableText>
                                <S.TableTextBillingSeconds
                                  data-tag="allowRowEvents"
                                  color={getColorForBilling(row.rrtTotalBillingSeconds, row.rrtMaxBillingSeconds, row.rrtMaxBillingUnits, false)}
                                  isBold={getIsBoldForBilling(row.rrtTotalBillingSeconds, row.rrtMaxBillingSeconds, row.rrtMaxBillingUnits, false)}
                                >
                                    {hasReachedRrtMaxBillingUnits(row) ? 'Max units reached' : `${Math.floor((row.rrtTotalBillingSeconds % (row.rrtMaxBillingSeconds / row.rrtMaxBillingUnits)) / 60)}/${Math.round(row.rrtMaxBillingSeconds / row.rrtMaxBillingUnits / 60)} min`}
                                </S.TableTextBillingSeconds>
                            </>
                        )}
                    </S.TableColumn>
                ),
            }
        ];
    } else {
        columns = [
            ...columns,
            {
                name: TableColumnHeader('Billing'),
                grow: 2,
                sortable: true,
                sortField: 'billing',
                cell: (row: IPatientListUser) => (
                    <>
                        <S.TableColumn data-tag="allowRowEvents">
                            <S.TableText isBold>RTM</S.TableText>
                            <S.TableText data-tag="allowRowEvents">
                                {/* TODO: Pull flat rate billing values from the backend */}
                                {hasReachedPtMaxBillingUnits(row)
                                        ? getPtMaxBillingUnitsForUser(row)
                                        : Math.floor(row.ptTotalBillingSeconds / row.ptBillingSecondsPerUnit)}/{row.flatRatePTBilling ? 2 : row.ptMaxBillingUnits} Units
                            </S.TableText>
                            <S.TableTextBillingSeconds
                              data-tag="allowRowEvents"
                              color={getColorForBilling(row.ptTotalBillingSeconds, getPtMaxBillingSeconds(row), getPtMaxBillingUnitsForUser(row), true)}
                              isBold={getIsBoldForBilling(row.ptTotalBillingSeconds, getPtMaxBillingSeconds(row), getPtMaxBillingUnitsForUser(row), true)}
                            >
                                {hasReachedPtMaxBillingUnits(row) ? 'Max units reached' : `${Math.floor((row.ptTotalBillingSeconds % (row.ptMaxBillingSeconds / row.ptMaxBillingUnits)) / 60)}/${Math.round(row.ptMaxBillingSeconds / row.ptMaxBillingUnits / 60)} min`}
                            </S.TableTextBillingSeconds>
                        </S.TableColumn>
                        <S.TableColumn data-tag="allowRowEvents">
                            <S.TableText isBold>PCM</S.TableText>
                            <S.TableText data-tag="allowRowEvents">
                                {hasReachedRrtMaxBillingUnits(row) ? row.rrtMaxBillingUnits : Math.floor(row.rrtTotalBillingSeconds / row.rrtBillingSecondsPerUnit)}/{row.rrtMaxBillingUnits} Units
                            </S.TableText>
                            <S.TableTextBillingSeconds
                              data-tag="allowRowEvents"
                              color={getColorForBilling(row.rrtTotalBillingSeconds, row.rrtMaxBillingSeconds, row.rrtMaxBillingUnits, false)}
                              isBold={getIsBoldForBilling(row.rrtTotalBillingSeconds, row.rrtMaxBillingSeconds, row.rrtMaxBillingUnits, false)}
                            >
                                {hasReachedRrtMaxBillingUnits(row) ? 'Max units reached' : `${Math.floor((row.rrtTotalBillingSeconds % (row.rrtMaxBillingSeconds / row.rrtMaxBillingUnits)) / 60)}/${Math.round(row.rrtMaxBillingSeconds / row.rrtMaxBillingUnits / 60)} min`}
                            </S.TableTextBillingSeconds>
                        </S.TableColumn>
                    </>
                ),
            },
        ];
    }

    return [
        ...columns,
        {
            selector: (row: IPatientListUser) => row.id,
            name: TableColumnHeader('Last Reviewed'),
            grow: 0.8,
            sortable: true,
            sortField: 'lastViewedAt',
            cell: PatientDataTableCells.ViewMoreCell,
        }
    ];
};

export const renderErrors = (errors: IError[]) => {
    if (!errors || errors.length === 0) {
        return <S.TableText>No errors found</S.TableText>;
    }

    return errors
        .slice()
        .sort((a, b) => (ErrorPriority[a.type] || Infinity) - (ErrorPriority[b.type] || Infinity))
        .map((error, index) => (
            <S.ErrorsColumnTableText key={index}>
                <PatientError type={error.type} message={error.message} />
            </S.ErrorsColumnTableText>
        ));
};

type ErrorColumnsProps = {
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
    totalErrors?: number;
    patientsWithErrors?: number;
};

export const errorColumns = ({ showErrors, setShowErrors, totalErrors }: ErrorColumnsProps) => ([
    {
        name: TableColumnHeader('Status'),
        grow: 1,
        cell: ErrorDataTableCells.StatusCell
    },
    {
        name: TableColumnHeader('Patient'),
        grow: 3,
        cell: ErrorDataTableCells.PatientCell
    },
    {
        name: TableColumnHeader('Errors'),
        grow: 4,
        cell: (row: IPatientError) => (
            <S.TableColumn>
                {renderErrors(row.errors)}
            </S.TableColumn>
        )
    },
    {
        name: (
            <CollapseExpandButton
              expanded={showErrors}
              onClick={() => setShowErrors(!showErrors)}
              expandedText={(
                  <>
                      <span>Collapse</span>
                      <FontAwesomeIcon icon={faArrowUpLong as IconProp} />
                  </>
              )}
              style={{ margin: 0, padding: 0, color: 'white' }}
            />
        ),
        grow: 1,
        cell: ErrorDataTableCells.ViewMoreCell
    },
]);

export const errorColumnsCollapsed = ({ showErrors, setShowErrors, patientsWithErrors }: ErrorColumnsProps) => ([
    {
        name: TableColumnHeader('Status'),
        grow: 1,
        center: true,
    },
    {
        name: TableColumnHeader('Patient'),
        grow: 3,
        cell: () => (
            <span>
                <strong>{patientsWithErrors}</strong> Unique {patientsWithErrors! > 1 ? 'Patients' : 'Patient'}
            </span>
        )
    },
    {
        name: TableColumnHeader('Errors'),
        grow: 4,
        cell: (row) => (
            <span>
                <strong>{row.totalErrors}</strong> Total Errors, <strong style={{ color: 'red' }}>{row.totalCriticalErrors}</strong> Critical, <strong>{row.totalStandardErrors}</strong> Standard
            </span>
        )
    },
    {
        name: (
            <CollapseExpandButton
              expanded={showErrors}
              onClick={() => setShowErrors(!showErrors)}
              collapsedText={(
                  <>
                      <span>Expand</span>
                      <FontAwesomeIcon icon={faArrowDownLong as IconProp} />
                  </>
              )}
              style={{ margin: 0, padding: 0, color: 'white' }}
            />
        ),
        grow: 1,
    },
]);
