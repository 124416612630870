import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ExercisePlanContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.shades.offwhite};
  padding-bottom: 10px;
`;

export const ProgramDetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
`;

export const ProgramDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  justify-content: flex-start;
  &:last-of-type {
    padding-right: 0;
  }
`;

export const ExerciseDateSelect = styled(Inputs.Input)`
  width: 90%;
  margin: 0;
`;

export const ExerciseButton = styled(Buttons.Button)`
  padding: 8px 30px;
`;

export const CalendarContainer = styled.div`
  margin-bottom: 50px;
`;

export const ProgramLengthButton = styled(Buttons.Button)`
  width: 30px;
  height: 30px;
  margin: 5px;
  padding: 0;
  border-radius: 5px;
`;

export const ProgramLengthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
`;

export const ExerciseTitle = styled.h4`
  margin: 8px 0;
  font-weight: bold;
`;

export const ExerciseText = styled.p`
  margin: 8px 0;
`;

export const HeartRateRange = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const HeartRateRangeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const RadioContainer = styled.div`
  margin-top: 15px;
`;

export const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  p {
    margin: 5px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const LockStateWarning = styled.span`
  font-size: 14px;
`;

export const PlanTypeContainer = styled.div<{status: 'success' | 'danger'}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => {
        switch (props.status) {
            case 'success':
                return props.theme.success.dark;
            case 'danger':
                return props.theme.danger.dark;
            default:
                return props.theme.primary.dark;
        }
    }};
  color: ${(props) => props.theme.shades.white};
  border-radius: 5px;
  padding: 10px;
  margin: 10px 5px;
  min-width: 150px;
  font-weight: bold;
`;
